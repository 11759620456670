


































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { lazyInject } from '@/ioc/container';

import Layout from '@/layouts/LayoutDefault.vue';

import Risk from '@models/proRisk/Risk';
import RiskCharacter from '@models/proRisk/RiskCharacter';
import ProFolder from '@models/proRisk/ProFolder';

import UnitMatrix from '@modules/professional-risk/models/UnitMatrix';
import { TableColumn } from 'element-ui/types/table-column';
import Unit from '@models/firm/Unit';
import SERVICE_IDENTIFIER from '../../ioc/identifiers';
import { RiskServiceInterface } from '../../services/RiskServiceInterface';

const firmModule = namespace('firm');
const riskProModule = namespace('riskPro');

const riskRepository = namespace('repositories/risk');
const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: {
    Layout,
  },
})
export default class UnitMatrixView extends Vue {
  @riskRepository.Getter('getAll')
  risks!: Risk[];

  @firmModule.Getter('getUnits')
  units!: Unit[];

  @riskRepository.Action('findByFolder')
  fetchRisk!: (proFolder: ProFolder) => Promise<void>;

  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @riskCharacterRepository.Getter('get')
  getCharacter!: (id: number) => RiskCharacter | null;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  matrix: Record<number, UnitMatrix[]> = {};

  characters: RiskCharacter[] = [];

  tableMaxHeight: number = 0;

  cellClassName(cell: { row: UnitMatrix, column: TableColumn, rowIndex: number, columnIndex: number }) {
    const { row, column, columnIndex } = cell;
    if (columnIndex === 0) {
      return '';
    }

    return row.getQuotation(Number(column.property)).color;
  }

  @Watch('risks')
  onRisksChange() {
    const riskCharacters = this.risks.filter(risk => risk.character).map(risk => risk.character) as RiskCharacter[];
    this.characters = [...new Set(riskCharacters.sort((a, b) => (a.label < b.label ? -1 : 1)))];
    this.matrix = this.riskService.generateMatrix(this.risks, this.units);
  }

  syncScroll() {
    setTimeout(() => {
      let left = 0;
      document.querySelectorAll('.el-table .el-table__body-wrapper').forEach((el) => {
        el.addEventListener('scroll', (e) => {
          const target = e.target as HTMLDivElement;
          left = target.scrollLeft;
          document.querySelectorAll('.el-table .el-table__body-wrapper, .el-table__header-wrapper').forEach((el1) => {
            el1.scrollLeft = left;
          });
        });
      });
    }, 500);
  }

  created() {
    if (this.proFolder) {
      this.fetchRisk(this.proFolder);
    }
    this.tableMaxHeight = window.innerHeight - 150;
  }

  mounted() {
    this.syncScroll();
  }
}
